<template>
  <div id="contentlogin" class="contentlogin">
    <loader :show="show" />
    <section class="bienvenida">
      <img src="../../assets/logotipo.png" alt="Logotipo Bool" />
      <br />
      <label>Bienvenido a Bo'ol V 1.0</label>
    </section>
    <form class="formulariologin">
      <div class="form-group" style="float:none !important;">
        <label for="email" size="15" maxlength="20" class="n5">E-mail</label>
        <input
          type="text"
          name="email"
          id="email"
          autocomplete="false"
          v-model="email"
          placeholder="Correo electronico"
        />
        <p style="color:red" v-if="errorEmail">*Ingresa un e-mail válido</p>
      </div>
      <div class="form-group">
        <label for="contrasena" minlenght="8" maxlength="20">Contraseña</label>
        <input
          type="password"
          name="password"
          id="password"
          autocomplete="false"
          v-model="password"
          @keyup.enter="validacion()"
          placeholder="Contraseña"
        />
        <p style="color: red" v-if="errorPassword">
          *Favor de ingresar contraseña
        </p>
        <br v-if="espacio" />
        <br v-if="espacio" />
      </div>
      <div class="form-group text-center">
        <button
          type="button"
          class="boton mt-2"
          style="float:none !important;"
          id="ingresar"
          @click="validacion()"
        >
          Ingresar
        </button>
      </div>
    </form>

    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">{{ message }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-sm-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
          <ul>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Loader from "@/components/Loader";
export default {
  components: {
    Loader,
  },
  data() {
    return {
      errorEmail: false,
      errorPassword: false,
      email: "",
      password: "",
      espacio: true,
      errors: [],
      error: false,
      show: false,
      message: "",
      respuesta: "",
    };
  },
  methods: {
    validacion: function() {
      this.validarEmail();
      //this.validarPassword();
      if (this.errors.length == 0) {
        this.errors = [];
        return this.identidad();
      } else {
        this.errors = [];
      }
    },
    identidad() {
      /*Api para email y contraseña*/
      this.show = true;
      //console.log("localAntes: ", localStorage.nombreEmpleadoGlobal);
      //se obtienen credenciales de usuario
      axios
        .post(Server + "/usuarios/login", {
          Email: this.email,
          Password: this.password,
        })
        .then((response) => {
          //console.log(response);
          if (response.data.empleadoId != null) {
            localStorage.empleadoIdGlobal = response.data.empleadoId;
            localStorage.nombreEmpleadoGlobal = response.data.nombreEmpleado;
            localStorage.nombrePuestoGlobal = response.data.nombrePuesto;
            localStorage.empresaIdGlobal = response.data.empresaId;
            localStorage.token = "Bearer " + response.data.token;
            localStorage.roles = response.data.roles;
          }
          this.show = false;
          /**Si el rol es básico redirecciona al directorio para no mostrar el dashboard */
          var rolesLocalStorage = localStorage.roles;
          var roles = rolesLocalStorage.split(",");
          //se valora que rol tiene el usuario para ver hacia donde se redireccion
          if (
            rolesLocalStorage.length > 0 &&
            (roles.includes("Basico") || roles.includes("Medio"))
          ) {
            this.$router.push("/directory");
          } else {
            this.$router.push("/dashboard");
          }
          /****************************************************************************** */
        })
        .catch((e) => {
          /*console.log(e);
          console.log(e.response.data);
          console.log(e.response.status);*/
          this.show = false;
          this.message = "Iniciar sesión";
          this.respuesta = "El email y/o contraseña son incorrectos.";
          this.error = true;
        });
    },
    //Valida expresion regular de email
    validarEmail() {
      //console.log("entro a Validar Email");
      var exp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (exp.test(this.email)) {
        this.espacio = false;
        this.errorEmail = false;
        //console.log("El correo esta bien");
        return false;
      } else {
        this.errorEmail = true;
        this.espacio = false;
        this.errors.push("Email inválido");
        //console.log("El correo no esta bien");
        return true;
      }
    },
    /*Valida expresion regular de password 
        La contraseña debe tener al entre 8 y 16 caracteres, al menos un dígito, 
        al menos una minúscula y al menos una mayúscula.NO puede tener otros símbolos*/
    validarPassword() {
      console.log("entro a Validar Password");
      var exp = /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/;
      if (exp.test(this.password)) {
        this.errorPassword = false;
        console.log("El password esta bien");
        return false;
      } else {
        this.espacio = false;
        this.errorPassword = true;
        this.errors.push("Password inválido");
        console.log("El password no cumple la expresion");
        return true;
      }
    },
  },
  computed: {
    ValidarEmail() {
      console.log("entro a Validar Email");
      var exp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (exp.test(this.email)) {
        this.errorEmail = false;
        return false;
      } else {
        this.espacio = false;
        this.errorEmail = true;
        return true;
      }
    },
  },
  created() {
    //Variables globales de Usuario
    localStorage.clear();
    localStorage.empleadoIdGlobal = 1;
    localStorage.nombreEmpleadoGlobal = "Pedro Suárez";
    localStorage.empresaIdGlobal = 1;
    localStorage.token = "null";
    localStorage.roles = "";
  },
};
</script>
